<template>
  <div v-loading="loading">
    <section>
      <article class="mt-5">
        <div class="container mt-5">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/categories' }">Categories</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: `/category/${$route.params.category_id}/${$route.params.category_name}/products` }">{{ $route.params.category_name }} - Products</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.params.product_name }} - Colors</el-breadcrumb-item>
                <!-- <el-breadcrumb-item>Colors</el-breadcrumb-item> -->
              </el-breadcrumb>
            </div>
          </div>

          <h3><b>Colors:</b></h3>
          <div
            style="display: flex; align-items:center; justify-content: space-between;"
            class="search_add_section"
          >
            <div style="width: 50%;">
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by Name"
                class="search_by_input form-control"
                spellcheck="off"
              />
            </div>
            <div>
              <el-button
                type="primary"
                :disabled="false"
                @click="$router.push( { path: `/category/${$route.params.category_id}/${$route.params.category_name}/products/${$route.params.product_id}/${$route.params.product_name}/add-color` } )"
                round
              >Add Color</el-button>
            </div>
          </div>
          <hr />

          <div
            v-loading="loading"
            class="table-responsive mb-5"
          >
            <data-tables
              :data="products"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="isLoading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getProducts"
                  v-else-if="!isLoading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Products Now. Please click here to retry
                </div>
                <div v-if="!isLoading && !loadingError">No Products</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Main Image"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="75px"
              >
                <template slot-scope="scope">
                  <img
                    :src="scope.row.cover_image"
                    style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover;"
                  >
                </template>
              </el-table-column>

              <el-table-column
                label="Featured Images"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <div
                    class=""
                    style="display:grid; grid-template-columns: 1fr 1fr"
                  >
                    <div
                      style="width: 50px; height: 50px;"
                      v-for="(image, index) in JSON.parse(scope.row.slide_product_images)"
                      :key="index"
                    >
                      <img
                        :src="image.path"
                        style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover;"
                      >
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Color Name"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <span style="font-weight: 800;"> {{ scope.row.color_name }} </span><br />
                  <small>Add On: {{ formattedDate(scope.row.created_date) }} </small><br />
                  <small>Edited On: {{ formattedDate(scope.row.updated_date) }} </small>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-tooltip
                      content="Edit"
                      placement="top"
                    >
                      <el-button
                        type="warning"
                        :disabled="false"
                        @click="$router.push( { path: `/category/${$route.params.category_id}/${$route.params.category_name}/products/${$route.params.product_id}/${$route.params.product_name}/edit-color/${scope.row.color_id}` } )"
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Delete"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        :disabled="false"
                        @click="deleteColor(scope.row.color_id)"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>

  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import { format, parseISO } from "date-fns";
export default {
  components: {
    ScaleOut,
  },

  data() {
    return {
      loading: false,
      isLoading: false,
      showUserEditDialog: false,
      products: [],
      user_data: {},

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "fullname",
          value: "",
        },
      ],
      loadingError: false,
    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
  },

  mounted() {
    this.getProducts();
  },

  methods: {
    showEditUserData(userData) {
      this.showUserEditDialog = true;
      this.user_data = userData;
    },

    closeEditUserData() {
      this.showUserEditDialog = false;
    },

    async getProducts() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `colors/products/${this.$route.params.product_id}`
        );
        if (request.data.success) {
          this.products = request.data.products;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch products now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async deleteColor(colorId) {
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this Color. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.delete(`colors/${colorId}`);
            if (
              request.data.success &&
              request.data.message === "COLOR_DELETED_SUCCESSFULLY"
            ) {
              this.getProducts();
              return this.$notify({
                title: "Success",
                message: "Color deleted successfully!",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            return this.$notify({
              title: "Unable to Delete",
              message: "Unable to delete the Color, Please try again",
              type: "error",
            });
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {
          //
        });
    },
  },
};
</script>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>